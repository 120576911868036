import React, { Component } from 'react';
import Slider from 'react-slick';

// Import Images
import bg1 from "../../images/background/optimized_clientssay.webp";

// Team Content
const content = [
  {
    name: "Saurabh Mehta",
    text: "I recently had my Honda Activa serviced from Ride N Repair and I couldn't be happier with the service I received. The mechanic was friendly and knowledgeable, and he took the time to explain the work that needed to be done and answer all of my questions. The service was completed in a timely manner. I highly recommend the Ride N Repair and the team for the two wheeler service.",
  },
  // ... other testimonials
];

class TestimonialSection extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <section
          className="section-area section-sp2 bg-gray ovbl-dark testimonial-area"
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
              <div className="clearfix mb-2">
                <h6 className="title-ext text-primary">TESTIMONIAL</h6>
                <h2 className="title mb-0">WHAT CLIENTS SAY</h2>
              </div>
            </div>
            <Slider {...settings} className="testimonial-carousel-1 slick-arrow-none">
              {content.map((item, index) => (
                <div className="slider-item" key={index}>
                  <div className="testimonial-bx">
                    <div className="testimonial-content">
                      <p>{item.text}</p>
                    </div>
                    <div className="client-info">
                      <div className="testimonial-info">
                        <h6 className="name">- {item.name}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </>
    );
  }
}

export default TestimonialSection;
