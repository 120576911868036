import React, {useState, useEffect}  from "react";
import { Helmet } from "react-helmet";
import Markup from './markup/markup';
import LogoSchema from "./markup/elements/logo-schema";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Modal Video
import 'react-modal-video/css/modal-video.min.css';

// Fonts
import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/font-awesome/css/font-awesome.min.css";
import "./vendor/flaticon/flaticon.css";
import "./vendor/themify/themify-icons.css";

// StyleSheet
import './App.scss';

import Router from "./Router";

// Import external script libraries
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';

import Chatbot from "./markup/elements/chatbot";

function App(props) {

	const { ssrLocation, isMobileBrowser: initialIsMobileBrowser } = props;
	
	const [user, setUser] = useState({ is_logged: false, email: '', fn: '', ln: '', ph: '' });
	const [userVehicles, setUserVehicles] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [citiesModal, setCitiesModal] = useState(false);
	const [isMobileApp, setIsMobileApp] = useState(false);
	const [isMobileBrowser, setIsMobileBrowser] = useState(initialIsMobileBrowser);
  const [showChatBot, setShowChatBot] = useState(true);

	useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      const isMobile = window.innerWidth <= 1024;
      setIsMobileBrowser(isMobile);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handler once to set the initial state based on current window size
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'PageView'
      });
      
      const isMobileApp = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;
      setIsMobileApp(isMobileApp);
    }

  }, []);

  // Initialize Google Tag Manager and Facebook Pixel
  useEffect(() => {
    // Initialize Google Tag Manager
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID
    };
    TagManager.initialize(tagManagerArgs);

    // Initialize Facebook Pixel
    const fbPixelId = process.env.REACT_APP_FB_PIXEL_ID;
    const fbPixelAdvancedMatching = {};
    const fbPixelOptions = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(fbPixelId, fbPixelAdvancedMatching, fbPixelOptions);
    ReactPixel.pageView();
  }, []);
	
	return (
    <>
      <Helmet>
        {/* Google Tag Manager Script */}
        <script
          defer
          src={`https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_GTM_ID}`}
        ></script>

        {/* Facebook Pixel Script */}
        <script
          defer
          src="https://connect.facebook.net/en_US/fbevents.js"
        ></script>
      </Helmet>
      <Router ssrLocation={ssrLocation}>
        <div className="page-wraper">
          <LogoSchema />
          <Markup setShowChatBot={setShowChatBot} isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} citiesModal={citiesModal} setCitiesModal={setCitiesModal} openModal={openModal} setOpenModal={setOpenModal} user={user} setUser={setUser} userVehicles={userVehicles} setUserVehicles={setUserVehicles} />
        </div>
      </Router>
      { showChatBot && <Chatbot isMobileApp={isMobileApp} user={user} setUser={setUser} /> }
    </>
	);
}

export default App;